var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-start",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
          staticStyle: { "margin-left": "50px !important" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
              staticStyle: { background: "rgba(255, 255, 255, 0.6)" },
            },
            [
              _c(
                "div",
                { staticClass: "px-8 pt-8 login-tabs-container" },
                [
                  _c("div", { staticClass: "vx-card__title mb-4" }, [
                    _c(
                      "h4",
                      { staticClass: "mb-4 text-primary" },
                      [
                        _c("logo", {
                          staticClass: "w-12 mr-4 fill-current text-primary",
                        }),
                        _vm._v("SIGCFC LOGIN "),
                      ],
                      1
                    ),
                  ]),
                  _c("login-jwt"),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }