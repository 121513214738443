<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-start" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4" style="margin-left: 50px !important">
      <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg" style="background: rgba(255, 255, 255, 0.6) ;">
        <div class="px-8 pt-8 login-tabs-container">

          <div class="vx-card__title mb-4">
            <h4 class="mb-4 text-primary">
              <logo class="w-12 mr-4 fill-current text-primary" />SIGCFC LOGIN </h4>
              <!-- <p>Entre com seus dados para acessar o SIGCFC.</p> -->
          </div>

          <!-- <vs-tabs> -->
            <!-- <vs-tab label="JWT"> -->
              <login-jwt></login-jwt>
            <!-- </vs-tab> -->
          <!-- </vs-tabs> -->

        </div>
      </div>
    </div>
  </div>

  <!-- <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login SIGCFC</h4> -->
                  <!-- <p>Entre com seus dados para acessar o SIGCFC.</p> -->
                <!-- </div> -->

                <!-- <vs-tabs> -->
                  <!-- <vs-tab label="JWT"> -->
                    <!-- <login-jwt></login-jwt> -->
                  <!-- </vs-tab> -->
                <!-- </vs-tabs> -->

              <!-- </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div> -->
</template>


<script>
import LoginJwt from './LoginJWT.vue'
import Logo from '@/layouts/components/Logo.vue'

export default {
  components: {
    LoginJwt,
    Logo
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 320px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
