var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|email|min:3",
            expression: "'required|email|min:3'",
          },
        ],
        staticClass: "w-full mt-10",
        attrs: {
          "data-vv-validate-on": "blur",
          name: "email",
          "data-vv-as": "E-mail",
          "icon-no-border": "",
          icon: "icon icon-user",
          "icon-pack": "feather",
          "label-placeholder": "E-mail",
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.loginJWT.apply(null, arguments)
          },
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("email"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:6|max:20",
            expression: "'required|min:6|max:20'",
          },
        ],
        staticClass: "w-full mt-6",
        attrs: {
          "data-vv-validate-on": "blur",
          type: "password",
          name: "password",
          "data-vv-as": "Senha",
          "icon-no-border": "",
          icon: "icon icon-lock",
          "icon-pack": "feather",
          "label-placeholder": "Senha",
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.loginJWT.apply(null, arguments)
          },
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("password"))),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between my-5" },
        [
          _c(
            "router-link",
            { staticClass: "mb-2", attrs: { to: "/forgot-password" } },
            [_vm._v("Esqueceu a senha?")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between mb-3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mb-6",
              attrs: { disabled: !_vm.validateForm },
              on: { click: _vm.loginJWT },
            },
            [_vm._v("Login")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }