<template>
  <div>
    <vs-input
        @keyup.enter="loginJWT"
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        data-vv-as="E-mail"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="E-mail"
        v-model="email"
        class="w-full mt-10"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
        @keyup.enter="loginJWT"
        data-vv-validate-on="blur"
        v-validate="'required|min:6|max:20'"
        type="password"
        name="password"
        data-vv-as="Senha"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Senha"
        v-model="password"
        class="w-full mt-6" />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between my-5">
        <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
        <router-link to="/forgot-password" class="mb-2">Esqueceu a senha?</router-link>
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <!-- <vs-button  type="border" @click="registerUser">Register</vs-button> -->
      <vs-button class="mb-6" :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  data () {
    return {
      email: null,
      password: null
      // checkbox_remember_me: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Tentativa de login',
          text: 'Você já está logado!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        router.push({ path: '/'})
        // return false
      }
      return true
    },
    loginJWT () {

      if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        // checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then(() => {

          // FUTURAMENTE DEVO ZERAR O STATE AO LOGIN EVITANDO O CARREGAMENTO DE ESTADO ANTERIOR DEIXADO POR UM LOGIN DE OUTRA EMPRESA
          // this.$store.replaceState({
          //   a: {},
          //   b: {}
          // })

          this.$vs.loading.close()
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    }
    // registerUser () {
    //   if (!this.checkLogin()) return
    //   this.$router.push('/pages/register').catch(() => {})
    // }
  }
}

</script>

